.about {
  background: $color-grey;
  color: $color-white;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.about__inner {
  padding-top: 180px;
  padding-bottom: 200px;

  .about--graph & {
    padding-bottom: 130px;
  }

  @include tablet {
    padding-top: 100px;
    padding-bottom: 100px;

    .about--graph & {
      padding-bottom: 100px;
    }
  }

  @include mobile {
    padding-top: 50px;
    padding-bottom: 50px;

    .about--graph & {
      padding-bottom: 60px;
    }
  }
}

.about__title {
  text-align: center;
  margin-bottom: 50px;

  @include tablet {
    font-size: 80px;
  }

  @include mobile {
    font-size: 34px;
    margin-bottom: 30px;
  }
}

.about__desc {
  text-align: center;
  font-size: 40px;
  line-height: 1.2;
  padding: 40px 140px 45px;
  position: relative;
  z-index: 1;
  font-weight: 700;

  margin-bottom: 35px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    height: 4px;
    background: linear-gradient(to right, transparent, $color-main);
  }

  &::after {
    top: auto;
    bottom: 0;
  }

  @include tablet {
    font-size: 32px;
    padding: 40px 30px;
  }

  @include mobile {
    font-size: 18px;
    padding: 19px 0;
  }
}

.about__content {
  display: flex;
  align-items: flex-start;

  @include tablet {
    display: block;
  }
}

.about__map {
  width: 70%;
  position: relative;

  @include tablet {
    width: auto;
    margin-bottom: 30px;
  }

  @include mobile {
    margin-bottom: 20px;
  }
}

.about__text {
  width: 30%;
  padding-left: 45px;
  font-size: 18px;
  padding-top: 20px;

  p {
    margin: 0;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include tablet {
    width: auto;
    padding-left: 0;
    font-size: 16px;
  }

  @include mobile {
    padding-top: 0;
    font-size: 14px;

    p {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.about__map-desc {
  position: absolute;
  z-index: 2;
  top: 25px;
  left: 0;
  max-width: 75%;
  font-size: 36px;
  line-height: 1.2;

  p {
    margin: 0;
  }

  @media (max-width: 1440px) {
    font-size: 24px;
  }

  @include tablet {
    font-size: 22px;
  }

  @include mobile {
    position: static;
    font-size: 18px;
    margin-top: 25px;
    max-width: none;
  }
}

.about__graph {
  background: url("../img/grid.svg") no-repeat;
  background-size: 100%;
  background-position: left 50% bottom 40px;
  margin-top: 50px;

  position: relative;
  z-index: 1;
}

.about__graph-text {
  position: absolute;
  z-index: 1;
  background: $color-white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  color: $color-grey;
  max-width: 570px;
  padding: 60px;
  left: 0;
  top: 40px;

  @media (max-width: 1440px) {
    padding: 40px;
  }

  @include laptop {
    position: static;
    margin-bottom: 30px;
  }

  @include tablet {
    padding: 40px 30px;
  }
}

.about__graph-title {

  font-size: 70px;
  line-height: 1;
  font-weight: 900;
  margin: 0;
  margin-bottom: 30px;

  // @media (max-width: 1440px) {
  //   font-size: 50px;
  // }

  @include tablet {
    font-size: 40px;
  }
}

.about__graph-desc {
  font-size: 18px;
  line-height: 1.5;
  margin: 0;

  @media (max-width: 1440px) {
    font-size: 16px;
  }
}

.about__graph-canvas {
  margin: 0 -40px;
  clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);

  .sal-animate & {
    animation: graph-enter 5s forwards;
  }

  @include mobile {
    margin: 0 -30px;
  }
}

@keyframes graph-enter {
  0% { clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%); }
  100% { clip-path: polygon(100% 0%, 0% 0%, -0% 100%, 100% 100%) }
}

.intro__inner {
  padding-top: 70px;
  padding-bottom: 70px;

  @include mobile {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.intro__title {
  text-align: center;
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 1;
  }
}

.intro__lead {
  max-width: 1050px;
  margin: 0 auto;
  text-align: center;

  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mobile {
    font-size: 18px;
    line-height: 1.2;
  }
}

.intro__image {
  margin-bottom: 70px;

  img {
    display: block;
    margin: 0 auto;
  }
}

.intro__text {
  display: flex;
  flex-wrap: wrap;
}

.intro__text-title {
  margin: 0;

  font-size: 34px;
  line-height: 1.3;
  width: 32%;
  margin-right: 5.6%;

  @include tablet {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  @include mobile {
    font-size: 20px;
  }
}

.intro__text-col {
  width: 32%;
  margin-right: 3.25%;

  p {
    margin: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--small {
    width: 27.15%;
    margin-right: 0;
  }

  @include tablet {
    width: 48%;
    margin-right: 2%;

    &--small {
      margin-right: 0;
    }
  }

  @include mobile {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.main-slider {
  margin-bottom: 110px;

  .swiper-container {
    background: $color-grey;
  }

  @include tablet {
    margin-bottom: 30px;
  }

  @include mobile {
    margin-bottom: 0;
  }
}

.main-slider__slide {
  position: relative;
  z-index: 1;
  height: auto;

  background: $color-grey;
  color: $color-white;

  @include tablet {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.main-slider__image {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.main-slider__text {
  padding-top: 60px;
  padding-bottom: 125px;

  @include mobile {
    padding-top: 31px;
    padding-bottom: 31px;
  }
}

.main-slider__title {
  line-height: 0.95;
  margin-bottom: 30px;
  overflow: hidden;
  padding-right: 30px;

  span {
    display: block;
    transform: translateY(100%);
    transition: transform $timing;
    transition-delay: 0.3s;

    .swiper-slide-active & {
      transform: translateY(0);
    }
  }

  @include laptop {
    font-size: 90px;
  }

  @include tablet {
    font-size: 64px;
  }

  @include mobile {
    font-size: 20px;
    margin-bottom: 0;
    max-width: none;
  }
}

.main-slider__desc {
  max-width: 386px;
  overflow: hidden;

  p {
    margin: 0;

    transform: translateY(100%);
    transition: transform $timing;
    transition-delay: 0.6s;

    .swiper-slide-active & {
      transform: translateY(0);
    }
  }

  @include mobile {
    display: none;
  }
}

.main-slider__pagination {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 40px;
  right: 0;

  .swiper-pagination {
    position: static;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .swiper-pagination-bullet {
    color: $color-white;
    width: auto;
    height: auto;
    flex-grow: 1;
    max-width: 110px;
    opacity: 0.3;
    background: none;
    border-radius: 0;
    margin-right: 30px;

    font-size: 14px;
    line-height: 20px;
    font-weight: 700;

    padding-bottom: 3px;
    border-bottom: 2px solid $color-white;
    transition: opacity $timing;

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:focus-visible,
    &.focus-visible {
      opacity: 1;
      outline: none;
    }
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  @include tablet {
    .swiper-pagination {
      padding-left: 24px;
      padding-right: 24px;
    }

    .swiper-pagination-bullet {
      margin-right: 10px;
    }
  }

  @include mobile {
    display: none;
  }
}

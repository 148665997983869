.news-list__inner {
  padding-top: 70px;
}

.news-list__title {
  text-align: center;
  margin-bottom: 80px;

  @include tablet {
    margin-bottom: 40px;
  }
}

.news-list__col {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-right: -1px;

  @include tablet {
    margin-right: 0;
  }
}

// .news-list__card {
//   width: 100%;
//   margin-bottom: -1px;

//   &--small {
//     width: 50%;
//     margin-right: -1px;

//     &:nth-child(2n) {
//       width: calc(50% + 1px);
//     }
//   }

//   @include mobile {
//     &--small {
//       width: 100%;
//       margin-right: 0;

//       &:nth-child(2n) {
//         width: 100%;
//       }
//     }
//   }
// }

// .news-list__items {
//   align-items: flex-start;
// }

// .news-list__col--sidebar {
//   .news-list__card {
//     width: 100%;
//   }

//   @include tablet {
//     .news-list__card {
//       width: 50%;
//     }
//   }

//   @include mobile {
//     .news-list__card {
//       width: 100%;
//       margin-right: 0;

//       &:nth-child(2n) {
//         width: 100%;
//       }
//     }
//   }
// }

.news-list__more {
  margin-top: 80px;
  text-align: center;
}

.news-list__items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 -15px;
  border: 1px solid $color-main;
  border-right: none;
  border-bottom: none;

  .news-card-bordered {
    height: 100%;
    border: none;
    border-bottom: 1px solid $color-main;
    border-right: 1px solid $color-main;;
  }

  .news-list__card:first-child {
    grid-column: 1 / 3;
    grid-row: span 3;
  }

  .news-list__card:nth-child(2) {
    grid-column: 1 / 3;
    grid-row: span 1;
  }

  .news-list__card:nth-child(3) {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
  }

  .news-list__card:nth-child(4) {
    grid-column: 3 / 4;
    grid-row: 3 / 5;
  }

  @include tablet {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    .news-list__card--small {
      width: 50%;
    }
  }

  @include mobile {
    margin: 0;
    .news-list__card--small {
      width: auto;
    }
  }
}

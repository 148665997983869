.about-map {
  position: relative;
  max-width: 1150px;
  margin: 0 auto;

  svg {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.about-map__dots {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.about-map-dot {

  // animation-iteration-count: infinite;
  // animation-direction: alternate;

  transform-origin: center center;
  transform-box: fill-box;

  &.active {
    animation-name: dot;
    animation-duration: 1.4s;
  }
}

@keyframes dot {
  0% {
    transform: translate3d(0px,0px,1px) scale(1);
    opacity: 0.5;
  }

  50% {
    transform: translate3d(0px,0px,1px) scale(1.7);
    opacity: 1;
  }

  100% {
    transform: translate3d(0px,0px,1px) scale(1);
    opacity: 0.5;
  }
}

.about-map__interval {
  animation-name: dot;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

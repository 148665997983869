.news {
  background: $color-grey;
  color: $color-white;
}

.news__inner {
  padding-top: 180px;
  padding-bottom: 180px;

  @include tablet {
    padding-top: 100px;
    padding-bottom: 100px;

    display: flex;
    flex-direction: column;
  }

  @include mobile {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.news__sidebar {
  @include tablet {
    order: -1;
    margin-bottom: 100px;
  }

  @include mobile {
    margin-bottom: 50px;
  }
}

.news__title {
  font-size: 70px;
  line-height: 1.1;
  font-weight: 900;
  margin: 0 0 100px;

  @include laptop {
    font-size: 56px;
  }

  @include tablet {
    margin-bottom: 40px;
  }

  @include mobile {
    font-size: 36px;
  }
}

.news__item {
  margin-bottom: 210px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1440px) {
    margin-bottom: 190px;
  }

  @include laptop {
    margin-bottom: 150px;
  }

  @include tablet {
    margin-bottom: 100px;
  }

  @include mobile {
    margin-bottom: 50px;
  }
}

.news__more {
  text-align: right;
  margin-bottom: 545px;

  @media (max-width: 1440px) {
    margin-bottom: 420px;
  }

  @include laptop {
    margin-bottom: 360px;
  }

  @include tablet {
    text-align: left;
    margin-bottom: 50px;
  }
}

.news__link {
  font-size: 22px;
  line-height: 1.2;
  font-weight: 800;
  display: inline-block;
  vertical-align: top;
  color: $color-main;
  text-decoration: underline;

  &:hover,
  &:focus-visible,
  &.focus-visible {
    text-decoration: none;
  }

  @include laptop {
    font-size: 18px;
  }

  @include mobile {
    font-size: 16px;
  }
}

.news__inner {
  display: grid;
  grid-template-columns: 8fr 4fr;
  grid-template-rows: auto auto auto 1fr;
  gap: 0 32px;

  @include tablet {
    grid-template-columns: 100%;
    grid-template-rows: none;
  }
}

.news__title,
.news__more,
.news__sidebar-list {
  grid-column: 2;

  @include tablet {
    grid-column: 1;
  }
}

.news__more {
  grid-row: 2;

  @include tablet {
    grid-row: auto;
    margin-bottom: 0;
  }
}

.news__sidebar-list {
  grid-row: 3;

  @include tablet {
    grid-row: auto;
    margin-bottom: 40px;
  }
}

.news__list {
  grid-column: 1;
  grid-row: 1 / -1;

  @include tablet {
    grid-row: auto;
    margin-bottom: 100px;
  }

  @include mobile {
    margin-bottom: 50px;
  }
}

.main-menu {
  font-size: 16px;
  line-height: 19px;

  @include tablet {
    font-size: 13px;
  }

  @include mobile {
    font-size: 22px;
    line-height: 26px
  }
}

.main-menu__list {
  @include list-reset;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.main-menu__item {
  margin-right: 31px;

  &:last-child {
    margin-right: 0;
  }

  @include tablet {
    margin-right: 20px;
  }

  @include mobile {
    margin-right: 0;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.main-menu__link {
  font-weight: 900;
  color: $color-grey;

}

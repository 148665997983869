.social-project-small {
  color: inherit;

  font-size: 16px;
  line-height: 1.5;

  p {
    margin: 0;
  }
}

.social-project-small__image {
  margin-bottom: 25px;
}

.social-project-small__title {
  font-size: 22px;
  line-height: 1;
  font-weight: 800;
  margin-bottom: 15px;
}

.social-project-small__more {
  margin-top: 30px;

  display: flex;
  align-items: center;

  font-weight: 800;
  font-size: 16px;
  line-height: 1;
  color: inherit;
  transition: color $timing;

  &:hover,
  &.focus-visible,
  &:focus-visible {
    color: $color-main;
    outline: none;
  }

  @include tablet {
    font-size: 14px;
  }
}

.social-project-small__more-text {
  margin-right: 7px;
}

.social-project-small__more-icon {
  svg {
    display: block;
    fill: none;
  }
}

.history-card {
  background: $color-white;
  box-shadow: 0px 10px 40px -10px rgba(27, 27, 28, 0.08);
  position: relative;
  display: flex;
  flex-direction: column;
}

.history-card__content {
  padding: 30px;

  @include mobile {
    padding: 20px 15px;
  }
}

.history-card__period {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 15px 30px;
  background: #15adc0;
  color: $color-white;

  font-weight: bold;
  font-size: 22px;
  line-height: 1;

  .history-card--no-image & {
    position: static;
    align-self: flex-end;
  }

  @include laptop {
    font-size: 18px;
  }

  @include mobile {
    font-size: 14px;
    padding: 8px 15px;
  }
}

.history-card__image {
  height: 330px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include laptop {
    height: 250px;
  }

  @include mobile {
    height: 170px;
  }
}

.history-card__title {
  font-size: 33px;
  line-height: 1.3;

  font-weight: bold;
  margin: 0 0 25px;

  @media (max-width: 1600px) {
    font-size: 24px;
  }

  @include mobile {
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 15px;
  }
}

.history-card__text {
  font-size: 18px;
  line-height: 1.5;
  margin: 0;

  @media (max-width: 1600px) {
    font-size: 16px;
  }

  @include mobile {
    font-size: 14px;
  }
}

.nav-menu__list {
  @include list-reset;
}

.nav-menu__item {
  &.active {
    .nav-menu__link {
      background: $color-main;
      color: $color-white;
      font-weight: 800;

      &::after {
        display: none;
      }
    }
  }

  .nav-menu--transparent & {
    &.active {
      .nav-menu__link {
        background: transparent;
        color: $color-grey;

        &::after {
          display: block;
          height: 2px;
          background: $color-main;
        }
      }
    }
  }
}

.nav-menu__link {
  display: block;
  padding: 18.3px 10px;
  color: $color-grey;
  position: relative;
  z-index: 1;
  font-size: 18px;
  line-height: 1.2;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: rgba($color-grey, 0.1);
  }

  &:hover,
  &.focus-visible,
  &:focus-visible {
    outline: none;
    background: $color-main;
    color: $color-white;

    &::after {
      display: none;
    }
  }

  .nav-menu--transparent & {
    padding: 18.3px 0;

    &:first-child {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 1px;
        background: rgba($color-grey, 0.1);
      }
    }

    &:hover,
    &.focus-visible,
    &:focus-visible {
      background: transparent;
      color: $color-grey;

      &::after {
        display: block;
        background: $color-main;
      }
    }
  }

  @include tablet {
    font-size: 16px;
  }
}

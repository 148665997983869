.banner__inner {
  background: $color-grey;
  color: $color-white;
  position: relative;
  z-index: 1;
  padding: 100px 140px;

  .banner--image & {
    padding: 0;
    display: flex;
    align-items: stretch;
  }

  @include tablet {
    padding: 100px 70px;

    .banner--image & {
      display: block;
    }
  }

  @include mobile {
    padding: 50px 24px;
  }
}

.banner__image {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .banner--image & {
    left: 50%;
    z-index: 1;
  }

  @include tablet {
    .banner--image & {
      position: static;
      height: 300px;
    }
  }

  @include mobile {
    img {
      object-position: 55% center;
    }

    .banner--image & {
      height: 200px;

      img {
        object-position: center;
      }
    }
  }
}

.banner__content {
  max-width: 60%;

  .banner--image & {
    padding: 140px 7px 140px 40px;
    max-width: 50%;
  }

  @media (max-width: 1650px) {
    .banner--image & {
      padding: 90px;
    }
  }

  @include laptop {
    .banner--image & {
      padding: 90px 40px;
    }
  }

  @include tablet {
    max-width: 70%;

    .banner--image & {
      max-width: none;
    }
  }

  @include mobile {
    max-width: none;

    .banner--image & {
      padding: 40px 24px;
    }
  }
}

.banner__title {
  font-size: 60px;
  line-height: 1.1;
  font-weight: 900;
  margin: 0 0 20px;

  @media (max-width: 1650px) {
    .banner--image & {
      font-size: 54px;
    }
  }

  @include laptop {
    font-size: 56px;

    .banner--image & {
      font-size: 48px;
    }
  }

  @include mobile {
    font-size: 28px;

    .banner--image & {
      font-size: 28px;
    }
  }
}

.banner__text {
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }


}

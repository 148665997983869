.social-projects {
  @include mobile {
    padding-bottom: 30px;
  }
}

.social-projects__list {
  display: flex;
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 60px;
  flex-wrap: wrap;

  .social-project {
    width: calc(33.33% + 4px);
    margin-right: -4px;

    &:nth-child(2) {
      &::before {
        top: 4%;
        bottom: 4%;
      }

      &::after {
        top: calc(4% + 2px);
        bottom: calc(4% + 2px);

      }
    }

    &:nth-child(3) {
      &::before {
        top: 7.7%;
        bottom: 7.7%;
      }

      &::after {
        top: calc(7.7% + 2px);
        bottom: calc(7.7% + 2px);
      }

      .social-project__content {
        padding: 0 10px;

        @media (max-width: 1440px) {
          padding: 0;
        }
      }
    }
  }

  @include tablet {
    padding: 50px 30px;


    .social-project {
      margin-right: -2px;
      width: 50%;
      margin-bottom: -2px;
    }
  }

  @include mobile {
    padding: 60px 0 30px;
    flex-wrap: nowrap;
    overflow: visible;

    .social-project {
      margin-right: 0;
      width: 100%;
      margin-bottom: 0;
    }
  }

  @media (min-width: 1024px) {
    .swiper-slide {
      height: auto;
    }
  }
}

.coverage-map + .social-projects {
  margin-top: -170px;

  @include tablet {
    margin-top: 0;
  }
}

.social-projects__tools {
  display: flex;
  align-items: flex-end;
  padding: 0 15px;

  display: none;

  @include mobile {
    display: flex;
  }
}

.social-projects__pagination {
  width: 130px;
  margin-right: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: $color-white;

  .swiper-pagination {
    display: flex;
    align-items: flex-end;
    position: static;
    width: 100%;
  }

  .swiper-pagination-bullet {
    width: auto;
    height: auto;

    flex-grow: 1;

    background: none;
    border-radius: 0;
    opacity: 1;
    color: rgba($color-grey, 0.2);;

    border-bottom: 1px solid rgba($color-grey, 0.2);
    transition: border-bottom $timing, color $timing;
    padding-bottom: 10px;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }

  .swiper-pagination-bullet-active {
    color: $color-grey;
    border-bottom-color: $color-grey
  }
}

.social-projects__navigaton {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.social-projects__btn {
  @include btn-reset;

  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid $color-main;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity $timing, background-color $timing, color $timing;
  margin-right: 40px;
  color: $color-main;

  &:last-child {
    margin-right: 0;
  }

  svg {
    display: block;
    fill: none;
  }

  &:hover {
    background-color: $color-main;
    color: $color-white;
  }

  &:active {
    background: $color-main-dark;
    border-color: $color-main-dark;
    color: $color-white;
  }

  &:focus-visible,
  &.focus-visible {
    border-color: $color-grey;
    outline: none;
  }

  &.swiper-button-disabled {
    border-color: #D1D1D2;
    color: #D1D1D2;
    background: none;
  }

  @include tablet {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

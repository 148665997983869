.lang-toggle__list {
  @include list-reset;
  display: flex;
  align-items: baseline;
}

.lang-toggle__item {
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &--active {
    .lang-toggle__link {
      opacity: 1;

      &::after {
        display: block;
      }
    }
  }

}

.lang-toggle__link {
  color: inherit;
  opacity: 0.3;
  transition: opacity $timing;
  position: relative;
  padding-bottom: 2px;

  &:hover,
  &.focus-visible,
  &:focus-visible {
    opacity: 1;
    outline: none;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    right: 0;
    height: 1px;
    background: $color-main;
    display: none;
  }
}

.social-links {
  display: flex;
  align-items: center;

  svg {
    display: block;
  }

  a {
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.documents {
  background: $color-gray-light;
}

.documents__header {
  display: flex;
  align-items: flex-start;

  margin-bottom: 90px;

  @include mobile {
    margin-bottom: 40px;
  }
}

.documents__inner {
  padding-top: 180px;
  padding-bottom: 180px;

  @include tablet {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include mobile {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.documents__header {
  display: flex;
  align-items: flex-start;

  @include tablet {
    display: block;
  }
}

.documents__title,
.documents__desc {
  width: 50%;

  @include tablet {
    width: auto;
  }
}

.documents__title {
  @media (max-width: 1600px) {
    font-size: 56px;
  }

  @include tablet {
    font-size: 72px;
    margin-bottom: 20px;
  }

  @include mobile {
    font-size: 34px;
  }
}

.documents__desc {
  font-size: 24px;
  line-height: 1.5;
  padding-left: 6.67%;
  max-width: 690px;

  p {
    margin: 0;
  }

  @media (max-width: 1600px) {
    font-size: 20px;
  }

  @include tablet {
    padding-left: 0;
  }
}

.documents__list-title {
  font-size: 36px;
  line-height: 1.4;
  font-weight: 500;
  margin: 0;

  @include tablet {
    margin-bottom: 30px;
  }
}

.documents__list {
  @include list-reset;
}

.documents__item {
  display: flex;
  justify-content: space-between;

  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid $color-grey;

  &:last-child {
    margin-bottom: 0;
  }

  @include mobile {
    display: block;
  }
}

.documents__show-link {
  max-width: calc(100% - 250px);

  @include mobile {
    max-width: none;
    margin-bottom: 30px;
  }
}


.documents__download-link {
  max-width: 200px;

  @include mobile {
    max-width: none;
  }
}

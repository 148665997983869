.link {
  display: inline-block;
  vertical-align: top;
  color: inherit;
  transition: color $timing;

  &:hover {
    color: $color-main;
    text-decoration: underline;
  }

  &.focus-visible,
  &:focus-visible {
    outline: none;
    color: $color-grey;
    text-decoration: underline;
  }

  &:active {
    color: $color-main-dark;
    text-decoration: underline;
  }

  &:disabled,
  &.disabled {
    color: #D1D1D2;
    pointer-events: none;
  }

  &--icon {
    display: flex;
    align-items: center;

    svg {
      display: block;
    }
  }

  &--bold {
    font-weight: 900;
  }

  &--dark {
    color: $color-white;

    &.focus-visible,
    &:focus-visible {
      color: $color-white;
    }

    &:disabled,
    &.disabled {
      color: #5F5F60;
    }
  }

  &--dark-icon {
    color: $color-main;

    &.focus-visible,
    &:focus-visible {
      color: $color-main;
      text-decoration: underline;
    }

    &:disabled,
    &.disabled {
      color: #5F5F60;
    }
  }
}

.link--icon,
.link--dark-icon {
  display: inline-flex;
  align-items: center;

  .link__icon + .link__text {
    margin-left: 5px;
  }

  .link__text + .link__icon {
    margin-left: 10px;
  }

  svg {
    display: block;
    fill: none;
  }
}



.info-card {
  border: 1px solid rgba($color-grey, 0.1);
  padding: 30px;
  display: flex;
  flex-direction: column;
  color: $color-grey;
  transition: border-color $timing, box-shadow $timing;
  box-shadow: inset 0 0 0 1px transparent;

  &:hover,
  &:focus-visible,
  &.focus-visible {
    outline: none;

    border-color: $color-main;
    box-shadow: inset 0 0 0 1px $color-main;
  }

  &--filled {
    background: $color-gray-light;
    border-color: transparent;
    box-shadow: none;
    transition: background-color $timing, color $timing;
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $color-main;
      transform: scale(1, 0);
      transition: transform $timing;
      transform-origin: center bottom;
    }

    &:hover,
    &:focus-visible,
    &.focus-visible {
      border-color: transparent;
      box-shadow: none;
      color: $color-white;

      &::after {
        transform: scale(1, 1);
      }
    }
  }

  &--dark {
    background: $color-black;
    color: $color-white;
  }

  @include tablet {
    padding: 24px;
  }

  @include mobile {
    padding: 20px 15px;
  }
}

.info-card__title {
  margin: 0;
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 15px;
  font-weight: 800;

  @include tablet {
    font-size: 18px;
  }
}

.info-card__text {
  margin: 0;
  margin-bottom: 40px;

  @include tablet {
    font-size: 14px;
  }

  @include mobile {
    margin-bottom: 20px;
  }
}

.info-card__more {
  margin-top: auto;
  display: flex;
  align-items: center;

  font-weight: 800;
  font-size: 16px;
  line-height: 1;

  @include tablet {
    font-size: 14px;
  }
}

.info-card__more-text {
  margin-right: 7px;
}

.info-card__more-icon {
  svg {
    display: block;
    fill: none;
  }
}

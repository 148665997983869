.page-header {
  font-size: 14px;
  line-height: 17px;

  border-bottom: 1px solid rgba($color-grey, 0.1);
  background: $color-white;

  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;

  transition: transform $timing;



  @media (min-width: 768px) {
    &--top {
      transform: translateY(-110%);
    }
  }
}

.page-header__inner {
  padding-top: 22px;
  padding-bottom: 30px;

  display: flex;
  align-items: center;

  @include mobile {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.page-header__menu-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;

  @include mobile {
    margin-right: 10px;

    .page-header--menu & {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
    }
  }
}

.page-header__block {
  margin-left: 25px;
  margin-bottom: 15px;

  &--menu {
    width: 100%;
    margin: 0;
  }

  &--search {
    .link__text {
      display: none;
    }
  }

  @include tablet {
    margin-bottom: 10px;
  }

  @include mobile {
    display: none;
    order: 5;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 15px;

    .page-header--menu & {
      display: block;
    }

    &--search {
      display: block;
      margin: 0;

      .page-header--menu & {
        margin-bottom: 20px;

        .link__text {
          display: block;
        }
      }
    }

    &--menu {
      order: 2;
      flex-grow: 1;
      border-bottom: 1px solid rgba($color-grey, 0.1);

      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      width: auto;
      align-self: stretch;
      padding-bottom: 15px;
    }

    &--phone,
    &--feedback {
      order: 3;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      align-self: stretch;
    }

    &--feedback {
      order: 4;
      padding-top: 0;
      border-top: none;
      border-bottom: 1px solid rgba($color-grey, 0.1);
      padding-bottom: 20px;
    }

    .page-header--fixed & {
      &--phone,
      &--feedback {
        display: none;
      }
    }
  }
}

.page-header__logo {
  flex-shrink: 0;
}

.page-header__menu {
  flex-grow: 1;

  @include mobile {
    display: flex;
    justify-content: flex-end;
  }
}

.page-header__menu-btn {
  @include btn-reset;
  display: none;

  width: 22px;
  height: 20px;
  justify-content: center;
  align-items: center;

  svg {
    display: block;
  }

  @include mobile {
    display: flex;

    &--close {
      display: none;
      margin-bottom: 40px;
    }

    .page-header--menu & {
      display: flex;
      align-self: flex-end;
      order: 1;
    }
  }
}

.page-header__menu-content {
  @include mobile {
    .page-header--menu & {
      position: fixed;
      z-index: 9999;
      top: 0;
      bottom: 0;
      left: 103px;
      right: 0;
      background: $color-white;
      padding: 15px;
      padding-top: 22px;
      margin-right: 0;
      display: flex;
    }
  }
}

.page-header__menu-overlay {
  display: none;
  position: fixed;
  z-index: 9998;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(27, 27, 28, 0.5);

  @include mobile {
    .page-header--menu & {
      display: block;
    }
  }
}

.page-header--fixed {
  box-shadow: 0px 10px 30px rgba(27, 27, 28, 0.1);

  .page-header__inner {
    position: relative;
    z-index: 1;
  }

  @media (min-width: 768px) {
    .page-header__inner {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .page-header__block {
      order: 3;
      margin-bottom: 10px;
      margin-left: 10px;

      &--cabinet {
        .link__text {
          display: none;
        }
      }

      &--lang,
      &--feedback {
        display: none;
      }

      &--phone {
        order: 2;

        .link--bold {
          font-weight: 700;
          opacity: 0.3;
        }
      }

      &--menu {
        width: auto;
        order: 1;
        margin-left: 50px;
      }
    }

    .main-menu__item {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    .page-header__menu-content {
      margin-bottom: -10px;
      max-width: 530px;
      margin-left: auto;
    }
  }


  @media (min-width: 1024px) {
    .page-header__block {
      margin-left: 25px;
      margin-bottom: 0;

      &--menu {
        order: 2;
        margin-left: 40px;
        margin-right: 15px;
      }

      &--phone {
        order: 1;
      }
    }

    .main-menu__item {
      margin-right: 31px;

      &:last-child {
        margin-right: 0;
      }
    }

    .page-header__menu-content {
      margin-bottom: 0;
      max-width: none;
      margin-left: 0;
    }
  }
}

.page-header__contacts {
  justify-content: space-between;
  padding: 10px 15px;
  align-items: center;
  border-top: 1px solid rgba($color-grey, 0.1);

  display: none;

  @include mobile {
    .page-header--fixed & {
      display: flex;
    }
  }
}

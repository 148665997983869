.coverage-map {
  position: relative;
  z-index: 1;
  background: linear-gradient(180deg, #FFFFFF -5.11%, #F0F0F0 79.44%);
}

.coverage-map__inner {
  padding-top: 140px;
  padding-bottom: 240px;

  @include tablet {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include mobile {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.coverage-map__title {
  margin-bottom: 30px;

  @include mobile {
    margin-bottom: 15px;
  }
}

.coverage-map__desc {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 70px;

  p {
    margin: 0;
  }

  @include mobile {
    font-size: 14px;
    margin-bottom: 30px;
  }
}

.coverage-map__features {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: -30px;

  .feature {
    margin-right: 80px;
    margin-bottom: 30px;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: 1440px) {
    .feature {
      margin-right: 40px;
    }
  }

  @include mobile {
    .feature {
      margin-right: 30px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

.coverage-map__text {
  padding-top: 20px;

  @include tablet {
    margin-bottom: 50px;
  }

  @include mobile {
    padding-top: 0;
  }
}

.coverage-map__image {
  overflow: hidden;

  img,
  svg {
    display: block;
    margin-left: auto;
  }

  svg {
    max-width: 100%;
    height: auto;
    transform: scale(2.5);

    transition: transform 2s;
    transition-delay: 2s;
  }

  &.sal-animate {
    svg {
      transform: scale(1);
    }
  }

  @include mobile {
    padding: 0;

    svg {
      transform: translateX(10px) scale(2.5);
    }

    &.sal-animate {
      svg {
        transform: translateX(10px) scale(1);
      }
    }

  }
}

.coverage-map__image-scroll {
  @include mobile {
    width: 100%;
    overflow: auto;

    svg {
      width: 500px;
      max-width: 500px;
    }
  }
}

.coverage-map__label {
  text {
    font-size: 18px;
  }

  &--moscow {
    text {
      font-size: 26px;
      transform: translateX(-20px);
    }
  }
}

.coverage-map__marker,
.coverage-map__lines,
.coverage-map__label {
  opacity: 0;
}

.coverage-map__moscow-border {

  transform-origin: center center;
  transform-box: fill-box;
  opacity: 0;

  // path {
  //   stroke-width: 0.5;
  // }
}

// .coverage-map__marker--moscow {
//   .coverage-map__marker-big,
//   .coverage-map__marker-small {
//     transform-origin: center center;
//     transform-box: fill-box;
//     transition: transform 0.5s;
//     transition-delay: 2s;

//     .sal-animate & {
//       transform: translate(0) scale(1);
//     }
//   }

//   .coverage-map__marker-big {
//     transform: translate(-2px, 16px) scale(1.55);
//   }

//   .coverage-map__marker-small {
//     transform: translate(-2px, 16px) scale(2);
//   }
// }

// первый шаг
.coverage-map__marker--moscow,
.coverage-map__label--moscow,
.coverage-map__moscow-border {
  .sal-animate & {
    animation: fadeIn 0.5s forwards;
    animation-delay: 0.5s;
  }
}

// второй шаг
.coverage-map__lines {
  .sal-animate & {
    animation: fadeIn 0.5s forwards;
    animation-delay: 1s;
  }
}

// третий шаг
.coverage-map__moscow-border {
  transition: transform 0.5s;
  transition-delay: 1.5s;

  // path {
  //   transition: stroke-width 0.5s;
  //   transition-delay: 2s;
  // }

  .sal-animate & {
    transform: scale(1);

    // path {
    //   stroke-width: 2;
    // }
  }
}

.coverage-map__marker,
.coverage-map__label {
  transition: opacity 0.5s;
  transition-delay: 1.5s;

  .sal-animate & {
    opacity: 1;
  }
}

// .coverage-map__label--moscow {
//   transform-origin: center center;
//   transform-box: fill-box;
//   transform: translate(-2px, -15px) scale(2.75);
//   transition: transform 0.5s, opacity 0.5s;
//   transition-delay: 2s;


//   .sal-animate & {
//     transform: translate(0) scale(1);
//     opacity: 1;
//   }
// }

// точки
$coverage-timing: 0.7s;

.coverage-map__marker-outer {
  transform-origin: center center;
  transform-box: fill-box;
  transform: scale(0);
  animation-duration: $coverage-timing;

  // .sal-animate & {
  //   &.active {
  //     animation: pulseFade 1s;
  //   }
  // }
}

// первый раз
.first-show {
  @for $i from 1 through 5 {
    [data-step="#{$i}"] {
      .coverage-map__marker-outer {
        animation-delay: 4 + $coverage-timing * ($i - 1);
      }
    }
  }
}

// первые 2 шага
@for $i from 1 through 5 {
  [data-step="#{$i}"] {
    .coverage-map__marker-outer {
      animation-delay: $coverage-timing * ($i - 1);
    }
  }
}

// шаг 1
.coverage-map__image.step-1.sal-animate {
  .group-1 .coverage-map__marker-outer {
    animation-name: pulseFade;
  }
}

// шаг 2
.coverage-map__image.step-2.sal-animate {
  .group-2 .coverage-map__marker-outer {
    animation-name: pulseFade;
  }
}


// шаг 3
.coverage-map__image.step-3.sal-animate {
  .group-3 .coverage-map__marker-outer {
    animation-name: pulseFade;
  }

  @for $i from 1 through 8 {
    [data-group-3-step="#{$i}"] {
      .coverage-map__marker-outer {
        animation-delay: $coverage-timing * ($i - 1);
      }
    }
  }
}

// шаг 4
.coverage-map__image.step-4.sal-animate {
  .group-4 .coverage-map__marker-outer {
    animation-name: pulseFade;
  }

  @for $i from 1 through 6 {
    [data-group-4-step="#{$i}"] {
      .coverage-map__marker-outer {
        animation-delay: $coverage-timing * ($i - 1);
      }
    }
  }
}

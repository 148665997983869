.news-card {
  color: $color-white;
}

.news-card__content {
  display: flex;
  align-items: flex-start;
  padding-left: 12.85%;
  margin-top: 20px;

  @include laptop {
    padding-left: 10%;
  }

  @include tablet {
    padding-left: 15%;
  }

  @include mobile {
    padding-left: 0;
    display: block;

  }
}

.news-card__text {
  width: 67.35%;

  transform: translateY(20%);
  opacity: 0;
  transition: transform $timing, opacity $timing;
  transition-delay: 1.1s;

  .sal-animate & {
    opacity: 1;
    transform: translateY(0);
  }

  @include mobile {
    width: auto;
  }
}

.news-card__title {
  margin-bottom: 40px;

  @media (max-width: 1440px) {
    font-size: 40px;
  }

  @include laptop {
    font-size: 32px;
  }

  @include tablet {
    font-size: 24px;
    line-height: 1.2;
  }

  @include mobile {
    font-size: 18px;
  }
}

.news-card__link {
  color: $color-main;
  text-decoration: underline;

  font-size: 22px;
  line-height: 26px;
  font-weight: 800;

  @include tablet {
    font-size: 18px;
    line-height: 21px;
  }

  @include mobile {
    font-size: 14px;
    line-height: 18px;
  }
}

.news-card__date {
  margin: 0;
  margin-top: 20px;
  font-size: 14px;
  line-height: 1;
  width: 32.65%;
  position: relative;
  z-index: 1;
  transform: translateY(20%);
  opacity: 0;
  transition: transform $timing, opacity $timing;
  transition-delay: 0.5s;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background: $color-main;
    transform: scale(0, 1);
    transition: transform $timing;
    transition-delay: 0.8s;
    transform-origin: left center;
  }

  .sal-animate & {
    opacity: 1;
    transform: translateY(0);

    &::after {
      transform: scale(1, 1);
    }
  }

  time {
    display: inline-block;
    vertical-align: top;
    background: $color-main;
    color: $color-white;
    padding: 5px 15px;
  }

  @media (max-width: 1440px) {
    margin-top: 10px;
  }

  @include mobile {
    width: auto;
    margin-bottom: 20px;
  }
}

.news-card__image {
  text-align: right;

  @include mobile {
    text-align: center;
  }
}

.news-card--medium {
  .news-card__image {
    max-width: 64.2%;
    margin-left: auto;
  }

  .news-card__title {
    font-size: 36px;
  }

  .news-card__link {
    font-size: 18px;
    line-height: 21px;
  }

  .news-card__date {
    margin-top: 10px;
  }

  @media (max-width: 1440px) {
    .news-card__title {
      font-size: 30px;
    }

    .news-card__date {
      margin-top: 5px;
    }
  }

  @include laptop {
    .news-card__title {
      font-size: 24px;
    }
  }

  @include tablet {
    .news-card__content {
      padding-left: 15%;
    }
  }

  @include mobile {
    .news-card__image {
      max-width: 100%;
    }

    .news-card__content {
      padding-left: 0;
    }

    .news-card__title {
      font-size: 18px;
    }

    .news-card__link {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.news-card--small {
  .news-card__content {
    padding-left: 0;
  }

  .news-card__date {
    width: 34%;
  }

  .news-card__text {
    width: 66%;
    padding-top: 10px;

  }

  .news-card__title {
    font-size: 18px;
    line-height: 22px;
  }

  .news-card__link {
    font-size: 16px;
    line-height: 19px;
  }

  .news-card__image {
    max-width: 79.25%;
    margin-left: auto;
  }

  .news-card__date {
    margin-top: 0;
  }

  @media (max-width: 1440px) {
    .news-card__text {
      padding-top: 5px;
    }
  }

  @include tablet {
    .news-card__image {
      max-width: 64.2%;
      margin-left: auto;
    }

    .news-card__title {
      font-size: 24px;
      line-height: 1.2;
    }

    .news-card__content {
      padding-left: 15%;
    }

    .news-card__link {
      font-size: 18px;
      line-height: 21px;
    }
  }

  @include mobile {
    .news-card__image {
      max-width: 100%;
    }

    .news-card__content {
      padding-left: 0;
    }

    .news-card__date {
      width: auto;
    }

    .news-card__text {
      width: auto;
    }

    .news-card__title {
      font-size: 18px;
    }

    .news-card__link {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.feature__value {
  padding-bottom: 6px;
  border-bottom: 4px solid $color-main;
}

.feature__title {
  margin: 0;
  margin-top: 10px;
  max-width: 210px;

  @include mobile {
    max-width: 130px;
    font-size: 12px;
    line-height: 1.4;

    &--mobile {
      .feature__title-text {
        display: none;

        &--mobile {
          display: block;
        }
      }
    }
  }
}

.feature__title-text {
  &--mobile {
    display: none;
  }
}

// @include font-face("Futura PT", "FuturaPT-Light", 300, normal, woff2 woff);

@font-face {
  font-family: 'SFProDisplay';
  src: url('../fonts/subset-SFProDisplay-Heavy.woff2') format('woff2'),
      url('../fonts/subset-SFProDisplay-Heavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../fonts/subset-SFProDisplay-Bold.woff2') format('woff2'),
      url('../fonts/subset-SFProDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../fonts/subset-SFProDisplay-Black.woff2') format('woff2'),
      url('../fonts/subset-SFProDisplay-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../fonts/subset-SFProDisplay-Regular.woff2') format('woff2'),
      url('../fonts/subset-SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../fonts/subset-SFProDisplay-Medium.woff2') format('woff2'),
      url('../fonts/subset-SFProDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../fonts/subset-SFProDisplay-Semibold.woff2') format('woff2'),
      url('../fonts/subset-SFProDisplay-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

.vacancies {

}

.vacancies__inner {
  padding-top: 70px;
  padding-bottom: 180px;

  @include mobile {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.vacancies__title {
  margin-bottom: 80px;

  @include mobile {
    margin-bottom: 30px;
  }
}

.vacancies__list {
  @include list-reset;
}

.vacancies__item {
  padding-bottom: 40px;
  border-bottom: 1px solid rgba($color-grey, 0.1);
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mobile {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

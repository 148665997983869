.news-list__card{
  img{
    object-fit: cover;
    object-position: center ;
    width: 100%;
    height: 400px;
  }
}
.news-list__card--small{
  img{
    height: 200px;
  }
}
.news-card-bordered {
  padding: 30px 15px;
  border: 1px solid $color-main;
  font-size: 18px;

  display: flex;
  flex-wrap: wrap;

  &--small {
    font-size: 16px;
  }


  &:hover {
    img {
      transform: scale(1.2);
    }
  }

  @include mobile {
    font-size: 16px;
  }
}
.news-card-bordered__date {
  margin: 0 0 10px;
  color: rgba($color-grey, 0.4);
}

.news-card-bordered__title {
  margin: 0 0 15px;

  font-size: 32px;
  line-height: 1.2;
  font-weight: 700;

  a {
    display: block;
    color: inherit;
    transition: color $timing;

    &:hover {
      color: $color-main;
    }
  }

  .news-card-bordered--small & {
    font-size: 24px;
  }

  @include mobile {
    font-size: 20px;
  }
}

.news-card-bordered__text {
  p {
    margin: 0;
  }
}

.news-card-bordered__image {
  width: 100%;
  margin-top: 30px;
  overflow: hidden;

  img {
    transition: transform $timing;
  }

  .news-card-bordered--small & {
    order: -1;
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.news-card-bordered__content {
  max-width: 72%;

  .news-card-bordered--small & {
    max-width: none;
    margin-bottom: 20px;
    width: 100%;
  }

  @include mobile {
    max-width: none;
  }
}

.news-card-bordered__link {
  margin-left: auto;
  color: $color-grey;
  text-decoration: underline;
  font-weight: 500;
  align-self: flex-end;

}

.partners-card {
  background: $color-light;
  min-height: 100%;

  padding: 50px 70px;

  display: flex;
  flex-direction: column;
  transition: background-color $timing;

  color: $color-grey;
  outline: none;

  &:hover,
  &.focus-visible,
  &:focus-visible {
    background: $color-main-light;
  }

  @media (max-width: 1440px) {
    padding: 35px 50px;
  }

  @include tablet {
    padding: 30px;
  }
}

.partners-card__image {
  height: 160px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: -60px;
  margin-bottom: 15px;

  @media (max-width: 1440px) {
    margin-right: 0;
  }

  @include mobile {
    height: auto;
  }
}

.partners-card__content {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include tablet {
    font-size: 16px;
  }

  @include mobile {
    font-size: 14px;
  }
}

.partners-card__text {
  margin-bottom: 20px;
  min-height: 125px;

  p {
    margin: 0 0 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mobile {
    min-height: auto;
  }
}

.partners-card__link {
  margin-top: auto;
  color: $color-main;
  font-weight: 800;
}

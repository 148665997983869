.page-footer {
  color: $color-gray;
  font-size: 16px;
  line-height: 19px;
  padding-top: 105px;
  overflow: hidden;

  @include mobile {
    margin-top: 0;
    font-size: 14px;
    line-height: 17px;
    padding-top: 64px;
    margin-top: -64px;
  }
}

.page-footer-wrapper {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 142px;
    height: 142px;
    left: 0;
    top: 12%;
    background: url("../img/dot.svg") no-repeat;
    background-size: 100%;
    transform: translate(-68px, -68px);
    animation: footer 5s infinite linear;
  }

  @include mobile {
    border-top: 4px solid #5AEAEA;

    &::after {
      animation: footerMobile 5s infinite linear;
      transform: none;
      left: -64px;
      top: -64px;
      width: 128px;
      height: 128px;
    }
  }
}

.page-footer__content {

  clip-path: polygon(22% 0%, 78% 0%, 100% 12%, 100% 100%, 0px 100%, 0% 12%);
  position: relative;
  z-index: 1;
  background: $color-main;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    background: linear-gradient(180deg, #485152 -3.93%, #1B1B1C 44.24%);
    clip-path: polygon(22% 0%, 78% 0%, 100% 12%, 100% 100%, 0px 100%, 0% 12%);
    top: 4px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @include mobile {
    clip-path: none;
    background: linear-gradient(180deg, #485152 -18.68%, #1B1B1C 36.34%);

    &::after {
      display: none;
    }
  }


}

.page-footer__inner {
  padding-top: 130px;
  padding-bottom: 70px;

  @include mobile {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.page-footer__section {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: -15px;
    width: 1px;
    background: rgba($color-white, 0.3);
  }

  &:first-child {
    &::after {
      display: none;
    }
  }

  @include tablet {
    margin-top: 50px;

    &::after {
      display: none;
    }

    &:nth-child(2n) {
      &::after {
        display: block;
      }
    }

    &:first-child,
    &:nth-child(2) {
      margin-top: 0;
    }
  }

  @include mobile {
    margin: 0 15px;
    padding: 25px 0;
    width: 100%;
    flex: auto;

    border-bottom: 1px solid rgba($color-white, 0.3);

    &:nth-child(2n) {
      &::after {
        display: none;
      }
    }
  }
}

.page-footer__section-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 800;
  color: $color-main;
  margin: 0;
  margin-bottom: 30px;

  @include mobile {
    margin-bottom: 20px;

    font-size: 14px;
    line-height: 17px;
    margin-bottom: 20px;
  }
}

.page-footer__contacts {
  @include list-reset;
  max-width: 300px;
}

.page-footer__contacts-item {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    transition: color $timing;

    &:hover,
    &:focus-visible,
    &.focus-visible {
      color: $color-main;
      outline: none;
    }
  }
}

.page-footer__top {
  align-items: stretch;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 15px;
    right: 15px;
    background: rgba($color-white, 0.3);
    height: 1px;
  }

  @include mobile {
    padding-bottom: 0;
    margin-bottom: 25px;

    &::after {
      display: none;
    }
  }
}

.page-footer__bottom {
  display: flex;
  justify-content: flex-start;

  font-size: 14px;
  line-height: 17px;

  @include mobile {
    display: block;

    font-size: 12px;
    line-height: 14px;
  }
}

.page-footer__block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:last-child {
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 10px;
  }

  @include tablet {
    margin-bottom: 20px;
    display: block;
    padding-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mobile {
    &:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.page-footer__logo,
.page-footer__copyright {
  margin-right: 30px;

  @include tablet {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.page-footer__logo {
  @include mobile {
    margin-bottom: 0;
  }
}

.page-footer__policy {
  .link {
    color: inherit;
  }
}

.page-footer__copyright {
  @include tablet {
    margin-bottom: 10px;
  }
}

.page-footer__link {
  margin-left: 20px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .link {
    color: inherit;
  }

  @include tablet {
    margin-left: 0;
    margin-top: 10px;
  }
}

.social-project {
  position: relative;
  z-index: 1;

  padding: 165px 70px 145px;
  display: flex;
  flex-direction: column;
  color: $color-grey;


  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: -1;

    clip-path: polygon(0 0, 100% 4%, 100% 96%, 0% 100%);

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: $color-gray;
    transition: transform $timing;
  }

  &::after {
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    background: $color-white;
  }

  &:hover,
  &:focus-visible,
  &.focus-visible {
    z-index: 2;
    filter: drop-shadow(0px 20px 30px rgba($color-black, 0.1));
    outline: none;

    &::before,
    &::after {
      transform: scale(1.1);
    }

    &::before {
      background: $color-main;
    }

    &::after {
      background: linear-gradient(180deg, #FFFFFF 0%, #F0F0F0 100%);
    }
  }


  @include laptop {
    padding: 135px 50px 135px;
  }

  @include tablet {
    padding: 30px;
    border: 2px solid $color-gray;

    &::before,
    &::after {
      display: none;
    }
  }

  @include mobile {
    border: 4px solid $color-gray;
    padding: 46px 11px;
    text-align: center;
  }
}

.social-project__image {
  min-height: 265px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 75px;
  flex-shrink: 0;

  @include mobile {
    min-height: auto;
    padding: 0;
    margin-bottom: 25px;

    img {
      width: auto;
      max-height: 127px;
    }
  }
}

.social-project__content {
  padding: 0 65px;
  padding-right: 55px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;


  @media (max-width: 1440px) {
    padding: 0;
  }
}

.social-project__title {
  margin: 0;

  font-size: 30px;
  line-height: 1;
  font-weight: 800;

  @include mobile {
    font-size: 18px;
  }
}

.social-project__text {
  margin-top: 15px;
  flex-grow: 1;
  margin-bottom: 40px;

  font-size: 18px;
  line-height: 1.5;

  p {
    margin: 0;
  }

  @include mobile {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.social-project__more {
  margin-top: auto;

  display: flex;
  align-items: center;

  font-weight: 800;
  font-size: 16px;
  line-height: 1;

  @include tablet {
    font-size: 14px;
  }

  @include mobile {
    align-self: center;
  }
}

.social-project__more-text {
  margin-right: 7px;
}

.social-project__more-icon {
  svg {
    display: block;
    fill: none;
  }
}

.news-article {
  font-size: 18px;
  line-height: 1.5;

  @include mobile {
    font-size: 16px;
    line-height: 1.4;
  }
}

.news-article__inner {
  padding-top: 70px;
  padding-bottom: 70px;
}

.news-article__title {
  font-size: 70px;
  line-height: 1.1;
  font-weight: 900;
  margin: 0 0 20px;

  @include tablet {
    font-size: 56px;
  }

  @include mobile {
    font-size: 32px;
  }
}

.news-article__date {
  margin: 0;
  font-size: 22px;
  font-size: 1.4;
  margin-bottom: 20px;
}

.news-article__image {
  margin-bottom: 80px;

  img {
    width: 100%;
    height: auto;
  }
}

.news-article__text {
  h2 {
    font-size: 44px;
    line-height: 1.2;
    font-weight: 700;
    margin: 0;
    margin-bottom: 50px;
  }

  p {
    margin: 30px 0;
    max-width: 80%;
  }

  a {
    font-weight: 700;
    color: inherit;
    transition: color $timing;

    &:hover,
    &.focus-visible,
    &:focus-visible {
      color: $color-main;
      outline: none;
    }
  }

  *:last-child {
    margin-bottom: 0;
  }


  .mt-50 {
    margin-top: 50px;
  }

  @include laptop {
    h2 {
      font-size: 32px;
    }

    p {
      max-width: none;
    }
  }

  @include tablet {
    margin-bottom: 30px;
  }

  @include mobile {
    h2 {
      font-size: 20px;
      margin-bottom: 32px;
    }

    p {
      margin: 15px 0;
    }

    .mt-50 {
      margin-top: 32px;
    }
  }
}

.news-article__sidebar {
  font-size: 16px;
  line-height: 1.2;
}

.news-article__link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: inherit;
  margin-bottom: 50px;
  transition: color $timing;

  &:hover,
  &.focus-visible,
  &:focus-visible {
    color: $color-main;
    outline: none;
  }
}

.news-article__link-title {
  color: $color-main;
  position: relative;
  padding-right: 30px;
  margin-bottom: 15px;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 21px;
    height: 12px;
    background: url("../img/arrow-right-long.svg") no-repeat;
  }

  &--prev {
    padding-right: 0;
    padding-left: 30px;

    &::after {
      right: auto;
      left: 0;
      background-image: url("../img/arrow-left-long.svg");
    }
  }
}

.news-article__all-link {
  display: block;
  padding: 22px 50px;
  text-align: center;
}

.news-article__sidebar-inner {
  max-width: 80%;
  margin-left: auto;
  background: $color-gray-light;
  padding: 70px 30px;

  @include laptop {
    max-width: none;
    margin: 0;
  }

  @include mobile {
    margin: 0 -24px;
    padding: 35px 24px;
  }
}

.news-article__other-news {
  margin-bottom: 190px;

  @include tablet {
    margin-bottom: 50px;
  }
}

.news-article__slider {
  width: 100%;
}

.pdf-link {
  display: flex;
  align-items: center;

  color: inherit;
  transition: color $timing;

  &:hover,
  &.focus-visible,
  &:focus-visible {
    color: $color-main;
    outline: none;

    .pdf-link__bg {
      fill: #15adc0;
    }

    .pdf-link__top {
      fill: #048d98;
    }
  }
}

.pdf-link__icon {
  margin-right: 20px;

  svg {
    display: block;
  }
}

.pdf-link__bg,
.pdf-link__top {
  fill: #d0eff2;
  transition: fill $timing;
}

.pdf-link__top {
  fill: #b7e8ec;
}

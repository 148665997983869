.presentation {
  overflow: hidden;
}

.presentation__inner {
  display: flex;
  align-items: stretch;
  padding-top: 180px;
  padding-bottom: 180px;

  @include tablet {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include mobile {
    padding-top: 60px;
    padding-bottom: 60px;
    display: block;
  }
}

.presentation__sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 340px;
  flex-shrink: 0;

  @include tablet {
    width: 300px;
  }

  @include mobile {
    display: block;
    margin-bottom: 40px;
  }
}

.presentation__image {
  margin-right: calc((100vw - 1650px) / -2);
  width: 100%;
  max-width: 1440px;

  @media (max-width: 1709px) {
    margin-right: -30px;
  }

  @include mobile {
    margin-right: 0;
  }
}

.presentation__pdf {
  width: 100%;

  &.pdfobject-container {
    height: 810px;
  }

  @include laptop {
    &.pdfobject-container {
      height: 500px;
    }
  }

  @include mobile {
    display: none;

    &.pdfobject-container {
      display: block;
    }
  }
}

.presentation__title {
  margin: 0;

  @include mobile {
    margin-bottom: 20px;
  }
}

.presentation__slide {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.presentation__navigaton {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba($color-white, 0.3);
  padding: 10px;
}

.presentation__btn {
  @include btn-reset;

  display: block;

  border: 20px solid transparent;
  border-top-width: 15px;
  border-bottom-width: 15px;
  border-left-color: $color-main;
  border-right-width: 0;

  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  &.js-slide-prev {
    border-left: 0 solid transparent;
    border-right: 20px solid $color-main;
  }
}

.field {
  position: relative;
  z-index: 1;

  input,
  textarea {
    display: block;
    width: 100%;
    background: none;
    border: none;
    border-bottom: 1px solid rgba($color-white, 0.5);
    color: $color-white;

    font-size: 18px;
    line-height: 25px;
    outline: none;
    padding: 20px 0;

    transition: border-color $timing;

    &::placeholder {
      opacity: 1;
    }

    &:hover,
    &.focus-visible,
    &:focus-visible {
      border-bottom-color: $color-white;
    }
  }

  textarea {
    resize: none;
    height: 65px;

    max-height: 150px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 2px;
      height: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: $color-white;
    }

    scrollbar-width: thin;
    scrollbar-color: $color-white transparent;
  }

  &--error {
    input,
    textarea {
      border-bottom-color: $color-red;
      padding-right: 200px;

      &:hover,
      &.focus-visible,
      &:focus-visible {
        border-bottom-color: $color-red;
      }
    }
  }

  @include mobile {
    input,
    textarea {
      font-size: 16px;
      padding: 20px 0;
    }
  }
}

.field__label {
  font-size: 16px;
  line-height: 22px;
  position: absolute;
  z-index: 1;
  top: 20px;

  left: 0;
  right: 0;
  color: $color-gray;
  transition: font-size $timing, transform $timing;
  transform-origin: left center;
  background: $color-grey;

  .field--filled & {
    transform: translateY(-100%);
    font-size: 12px;
  }
}

.field__error {
  color: $color-red;

  position: absolute;
  z-index: 2;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.field--dark {
  input,
  textarea {
    border-bottom-color: rgba($color-grey, 0.2);
    color: $color-grey;

    &:hover,
    &.focus-visible,
    &:focus-visible {
      border-bottom-color: $color-grey;
    }
  }

  .field__label {
    color: $color-grey;
    background: $color-white;
  }
}

.field--select {
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 16px;
    height: 13px;
    background: url(../img/feedback-select.svg) no-repeat;
    right: 10px;
    top: 50%;

    transform: translateY(-50%);
  }

  select {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    padding: 20px 0;
    padding-right: 35px;
    border-bottom: 1px solid rgba($color-white, 0.5);
    color: $color-gray;

    width: 100%;
    -webkit-appearance: none;
    appearance : none;

    &:hover,
    &.focus-visible,
    &:focus-visible {
      border-bottom-color: $color-white;
    }

    option {
      color: $color-grey;

      &:disabled {
        color: rgba($color-grey, 0.5);
      }
    }
  }
}

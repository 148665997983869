.feedback {
  position: relative;
  z-index: 1;
  padding-top: 235px;

  @include tablet {
    padding-top: 140px;
  }

  @include mobile {
    padding-top: 70px;
  }
}

.feedback__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 31%;
}

.feedback__bg-inner {
  position: absolute;
  z-index: 1;
  background: url("../img/wave.png") no-repeat;
  background-size: 100% auto;
  background-position: center top;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include mobile {
    background-size: 616px auto;
  }
}

.feedback__wrap {
  background: $color-grey;
  color: $color-white;

  padding: 125px 140px 140px;

  display: flex;
  align-items: flex-start;
  margin: 0 110px;

  @media (max-width: 1440px) {
    margin: 0 50px;
    padding: 100px;
  }

  @include laptop {
    margin: 0;
  }

  @include tablet {
    display: block;
  }

  @include mobile {
    padding: 50px 15px 60px;
    margin: 0 -15px;
  }
}

.feedback__title {
  margin-bottom: 30px;

  @include mobile {
    margin-bottom: 15px;
  }
}

.feedback_desc {
  font-size: 18px;
  line-height: 1.5;
  max-width: 400px;

  p {
    margin: 0;
  }

  @include mobile {
    font-size: 14px;
    line-height: 1.4;
  }
}

.feedback__text {
  max-width: 440px;
  margin-right: 120px;
  padding-top: 15px;
  padding-bottom: 15px;

  @include laptop {
    margin-right: 50px;
    max-width: 400px;
    padding-right: 0;
  }

  @include tablet {
    max-width: 500px;
    margin-bottom: 30px;
  }

  @include mobile {
    max-width: none;
    margin-right: 0;
    padding: 0;
  }
}

.feedback__form {
  max-width: 590px;
  flex-grow: 1;
}

.feedback__submit {
  .btn {
    display: block;
    width: 100%;
    padding-top: 23px;
    padding-bottom: 23px;

    &:hover,
    &.focus-visible,
    &:focus-visible {
      color: $color-white;
    }
  }
}

.feedback__fields {
  margin-bottom: 70px;
  padding-top: 5px;

  .field {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .field__error {
    background: $color-grey;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @include mobile {
    margin-bottom: 40px;

    .field {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.feedback__policy {
  margin-top: 30px;
  font-size: 14px;
  line-height: 1.4;
  max-width: 420px;

  p {
    margin: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mobile {
    font-size: 10px;
    margin-top: 20px;
  }
}

.feedback__policy-link a {
  font-size: 14px;
  line-height: 17px;

  font-weight: bold;
  color: $color-main;
  text-decoration: underline;

  &:hover,
  &:focus-visible,
  &.focus-visible {
    text-decoration: none;
    outline: none;
  }

  @include mobile {
    color: $color-white;
    text-decoration: none;

    font-size: 12px;
  }
}

.feedback--big {
  background: $color-grey;
  padding-top: 150px;
  padding-bottom: 70px;

  .feedback__bg {
    display: none;
  }

  .feedback__wrap {
    margin: 0;
    background: none;
    padding: 0;
  }

  & + .page-footer {
    background: $color-grey;
    margin-top: 0;

    @media (min-width: 768px) {
      &::after {
        display: none;
      }
    }

    @include mobile {
      margin-top: -64px;
    }

  }

  @include mobile {
    padding-top: 70px;
    margin: 0;
  }
}

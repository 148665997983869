.article .user-text {
  padding: 95px 140px;

  @media (max-width: 1650px) {
    padding: 90px;
  }

  @include laptop {
    padding: 90px 40px;
  }

  @include mobile {
    padding: 60px 0;
  }
}

.small-menu {
  @include list-reset;

  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;
  max-width: 370px;

  @include mobile {
    max-width: none;
    margin-bottom: 0;
    display: block;
  }
}

.small-menu__item {
  width: 140px;
  margin-right: 30px;
  margin-bottom: 15px;

  &:nth-child(2n) {
    width: 200px;
    margin-right: 0;
  }

  @include tablet {
    margin-right: 25px;
  }

  @include mobile {
    margin-right: 0;
    width: auto;
    margin-bottom: 10px;

    &:nth-child(2n) {
      width: auto;
      margin-right: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.small-menu__link {
  color: inherit;
  transition: color $timing;

  &:hover,
  &:focus-visible,
  &.focus-visible {
    color: $color-main;
    outline: none;
  }
}

.search__inner {
  padding-top: 116px;
  padding-bottom: 120px;

  @include mobile {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.search__field {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 56px;
    height: 26px;
    background: url("../img/arrow-right-grey.svg") no-repeat;
    background-size: 100%;
  }

  input {
    display: block;
    width: 100%;
    border: none;
    padding: 0;

    font-size: 90px;
    line-height: 1;
    font-weight: 900;
    outline: none;
    padding-top: 34px;
    padding-bottom: 34px;
    border-bottom: 1px solid rgba($color-grey, 0.1);
    padding-right: 100px;
    appearance: none;

    &::placeholder {
      opacity: 1;
      color: rgba($color-grey, 0.1);
    }
  }

  @include tablet {
    input {
      font-size: 60px;
    }
  }

  @include mobile {
    &::after {
      width: 30px;
      height: 14px;
    }

    input {
      font-size: 30px;
      padding: 20px 0;
      padding-right: 40px;
    }
  }
}

.search__results {
  margin-top: 140px;

  @include tablet {
    margin-top: 70px;
  }
}

.search__results-title {
  margin: 0;
  font-size: 50px;
  line-height: 1.2;

  margin-bottom: 40px;

  @include tablet {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

.search__list {
  @include list-reset;
}

.search__link {
  font-size: 26px;
  line-height: 1.4;

  padding: 40px 0;
  border-bottom: 1px solid rgba($color-grey, 0.1);

  display: flex;
  align-items: center;

  color: inherit;
  transition: color $timing;

  &:hover,
  &.focus-visible,
  &:focus-visible {
    color: $color-main;
    outline: none;
  }

  @include tablet {
    font-size: 18px;
    padding: 20px 0;
  }

  @include mobile {
    font-size: 16px;

    display: block;
  }
}

.search__item-title {
  width: calc(100% - 200px);
  display: block;

  @include mobile {
    width: auto;
    margin-bottom: 20px;
  }
}

.search__more {
  display: flex;
  align-items: center;
  margin-left: auto;

  font-weight: 800;
  font-size: 16px;
  line-height: 1;

  @include tablet {
    font-size: 14px;
  }
}

.search__more-text {
  margin-right: 7px;
}

.search__more-icon {
  svg {
    display: block;
    fill: none;
  }
}

.video__inner {
  padding-top: 125px;
  padding-bottom: 125px;

  @include tablet {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  @include mobile {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.video__wrap {
  padding-bottom: 31.15%;
  position: relative;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video__btn {
  @include btn-reset;

  position: absolute;
  z-index: 1;
  bottom: 30px;
  right: 30px;

  width: 40px;
  height: 40px;

  background: rgba($color-black, 0.5);
  border-radius: 50%;

  svg {
    display: block;
    width: 40px;
    height: 40px;
    fill: $color-white;
  }

  @include tablet {
    bottom: 15px;
    right: 15px;
  }

  @include mobile {
    width: 24px;
    height: 24px;

    bottom: 10px;
    right: 10px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.video__icon {
  &--muted {
    display: none;

    .is-muted & {
      display: block;
    }
  }

  &--unmuted {
    .is-muted & {
      display: none;
    }
  }
}

.h1 {
  font-size: 110px;
  line-height: 1.1;
  margin: 0;
  font-weight: 900;

  @include tablet {
    font-size: 90px;
  }

  @include mobile {
    font-size: 44px;
  }
}

.text-medium {
  font-size: 18px;
  line-height: 1.5;

  @include mobile {
    font-size: 16px;
  }
}

.h2 {
  font-size: 90px;
  line-height: 1;
  font-weight: 900;
  margin: 0;

  @include tablet {
    font-size: 72px;
  }

  @include mobile {
    font-size: 34px;
  }
}

.h3 {
  font-size: 60px;
  line-height: 1.1;
  margin: 0;
  font-weight: 900;

  @include laptop {
    font-size: 48px;
  }

  @include mobile {
    font-size: 24px;
  }
}

.lead {
  font-size: 50px;
  line-height: 1.2;
  font-weight: 700;
  margin: 0;

  @include mobile {
    font-size: 24px;
  }
}

.partners__inner {
  padding-top: 65px;
  padding-bottom: 130px;

  @include mobile {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.partners__title {
  margin-bottom: 155px;

  @media (max-width: 1440px) {
    margin-bottom: 70px;
  }

  @include mobile {
    margin-bottom: 50px;
  }
}

.partners__list {
  display: flex;
  flex-wrap: wrap;

  margin: 0 -36px;
  margin-bottom: -72px;

  @media (max-width: 1440px) {
    margin: 0 -15px;
    margin-bottom: -30px;
  }
}

.partners__item {
  width: calc(100% / 3);
  padding: 0 36px;
  margin-bottom: 72px;

  @media (max-width: 1440px) {
    padding: 0 15px;
    margin-bottom: 30px;
  }

  @include laptop {
    width: calc(100% / 2);
  }

  @include mobile {
    width: 100%;
  }
}

@keyframes dots-pulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.25);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulseFade {
  0% {
    transform: scale(0);
    opacity: 0.7;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}


.text-anim {
  overflow: hidden;
}

.text-anim__inner {
  display: block;
  transform: translateY(100%);
  transition: transform $timing;
  transition-delay: 0.3s;

  .sal-animate & {
    transform: translateY(0);
  }

  [data-sal-delay="600"] & {
    transition-delay: 0.6s;
  }
}

@keyframes footer {
  0% {
    left: 0;
    top: 12%;
  }

  20% {
    left: 22%;
    top: 0;
  }

  80% {
    left: 78%;
    top: 0;
  }

  100% {
    left: 100%;
    top: 12%;
  }
}

@keyframes footerMobile {
  0% {
    left: -64px;
  }

  100% {
    left: 100%;
  }
}

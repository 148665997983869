
.content-slider {
  position: relative;
  z-index: 1;
}

.content-slider__slide {
  display: flex;
  align-items: flex-start;

  @include mobile {
    display: block;
  }
}

.content-slider__image,
.content-slider__content {
  width: 50%;
}

.content-slider__title {
  font-size: 36px;
  line-height: 1;
  margin: 0 0 30px;

  @include mobile {
    font-size: 28px;
    margin-bottom: 20px;
  }
}

.content-slider__text {
  font-size: 18px;
  line-height: 1.4;

  p {
    margin: 0 0 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $color-main;
    transition: color $timing;
    outline: none;

    &:hover,
    &.focus-visible,
    &:focus-visible {
      color: $color-main-dark;
    }
  }

  @include tablet {
    font-size: 16px;
  }
}

.content-slider__image {
  padding: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-gray-light;

  @include tablet {
    padding: 50px;
    width: 40%;
  }

  @include mobile {
    font-size: 16px;
    width: auto;
    margin-bottom: 40px;
  }
}

.content-slider__content {
  padding-left: 6.67%;
  max-width: 670px;
  padding-bottom: 70px;

  @include tablet {
    width: 60%;
    max-width: none;
  }

  @include mobile {
    width: auto;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 30px;
  }
}

.content-slider__tools {
  position: absolute;
  z-index: 2;
  left: 50%;
  right: 0;
  bottom: 0;
  padding-left: 6.67%;
  max-width: 670px;
  display: flex;
  align-items: flex-end;

  @include tablet {
    left: 40%;
  }

  @include mobile {
    position: static;
    padding-left: 0;
  }
}

.content-slider__navigaton {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.content-slider__btn {
  @include btn-reset;

  width: 49px;
  height: 49px;
  border-radius: 50%;
  border: 1px solid $color-main;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity $timing, background-color $timing, color $timing;
  margin-right: 40px;
  color: $color-main;

  &:last-child {
    margin-right: 0;
  }

  svg {
    display: block;
    fill: none;
  }

  &:hover {
    background-color: $color-main;
    color: $color-white;
  }

  &:active {
    background: $color-main-dark;
    border-color: $color-main-dark;
    color: $color-white;
  }

  &:focus-visible,
  &.focus-visible {
    border-color: $color-grey;
    outline: none;
  }

  &.swiper-button-disabled {
    border-color: #D1D1D2;
    color: #D1D1D2;
    background: none;
  }

  @include tablet {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.content-slider__pagination {
  width: 280px;
  margin-right: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: $color-white;

  // .swiper-pagination-progressbar {
  //   position: static;
  //   height: 1px;
  //   width: 100%;
  //   margin-top: 10px;

  //   .swiper-pagination-progressbar-fill {
  //     background: $color-grey;
  //   }
  // }

  .swiper-pagination {
    display: flex;
    align-items: flex-end;
    position: static;
    width: 100%;
  }

  .swiper-pagination-bullet {
    width: auto;
    height: auto;

    flex-grow: 1;

    background: none;
    border-radius: 0;
    opacity: 1;
    color: rgba($color-grey, 0.2);;

    border-bottom: 1px solid rgba($color-grey, 0.2);
    transition: border-bottom $timing, color $timing;
    padding-bottom: 10px;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }

  .swiper-pagination-bullet-active {
    color: $color-grey;
    border-bottom-color: $color-grey
  }
}

.content-slider__pages-count {
  opacity: 0.2;
}

.not-found {
  background: #00526b;

  color: $color-white;
  flex-grow: 1;
  margin-top: -40px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  // @include retina {
  //   background-image: url("../img/404-bg@2x.jpg");
  // }
}

.not-found__bg {
  position: absolute;
  z-index: -1;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .simpleParallax {
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.not-found__inner {
  max-width: 1150px;
  padding-top: 100px;
  padding-bottom: 110px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
}

.not-found__title {
  margin: 0 0 10px;

  font-size: 216px;
  line-height: 1;
  font-weight: 900;

  @include tablet {
    font-size: 200px;
  }

  @include mobile {
    font-size: 130px;
  }
}

.not-found__text {
  font-size: 24px;
  line-height: 1.5;

  margin: 0 0 60px;

  @include tablet {
    font-size: 20px;
  }

  @include mobile {
    font-size: 18px;
  }
}

.not-found__btn {
  font-size: 18px;
  padding: 19.5px 140px;
  width: 100%;
  max-width: 450px;
  text-align: center;

  &:hover,
  &.focus-visible,
  &:focus-visible {
    color: $color-white;
  }

  @include mobile {
    padding: 18px 50px;
    font-size: 16px;
  }
}

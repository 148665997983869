.tabs {

}

.tabs__inner {
  padding-top: 70px;
  padding-bottom: 125px;

  @include tablet {
    padding-bottom: 100px;
  }

  @include mobile {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.tabs__head {
  margin-bottom: 75px;

  @include tablet {
    margin-bottom: 50px;
  }

  @include mobile {
    margin-bottom: 32px;
  }
}

.tabs__desc {
  font-size: 24px;
  line-height: 1.5;
  padding-left: 43px;
  padding-top: 15px;
  padding-bottom: 15px;

  p {
    margin: 0;
  }

  @media (max-width: 1440px) {
    font-size: 20px;
  }

  @include tablet {
    font-size: 18px;
    padding-left: 0;
  }

  @include mobile {
    font-size: 14px;
    line-height: 1.4;
  }
}

.tabs__content-item {
  flex-wrap: wrap;
  margin-right: -30px;
  margin-bottom: -30px;

  display: none;

  &.active {
    display: flex;
  }

  @include tablet {
    margin-right: 0;
  }

  @include mobile {
    margin-bottom: 0;

    .info-card {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.tabs__card {
  width: calc((100% - 90px) / 3);
  margin-right: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  .info-card {
    flex-grow: 1;
  }


  @include laptop {
    width: calc((100% - 60px) / 2);
  }

  @include tablet {
    width: 100%;
    margin-right: 0;


    &:last-child {
      margin-bottom: 0;
    }
  }
}

.tabs__menu {
  .selectr-container {
    display: none;
  }

  @include mobile {
    margin-bottom: 20px;

    .selectr-container {
      display: block;
    }

    .nav-menu {
      display: none;
    }
  }
}

.btn {
  display: inline-block;
  vertical-align: top;
  color: $color-white;
  background: $color-main;
  transition: color $timing, background-color $timing, box-shadow $timing, border-color $timing;
  border: 4px solid $color-main;
  padding: 13px 60px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0px 10px 20px transparent;

  &:hover {
    box-shadow: 0px 10px 20px rgba(21, 173, 192, 0.6);
  }

  &:active {
    background-color: $color-main-dark;
    box-shadow: none;
    border-color: $color-main-dark;
  }

  &.focus-visible,
  &:focus-visible {
    border-color: $color-grey;
    outline: none;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    background:#B9E6EC;
    border-color: #B9E6EC;
    box-shadow: none;
    color: $color-white;
  }

  &--dark {
    &:hover {
      color: color-grey;
    }

    &.focus-visible,
    &:focus-visible {
      border-color: $color-white;
    }

    &:disabled,
    &.disabled {
      background: #19474D;
      border-color: #19474D;
      box-shadow: none;
      color: #5E7E82;
    }
  }

  &--outline,
  &--outline-dark {
    background: transparent;
    color: $color-grey;

    &:hover {
      box-shadow: none;
      background-color: $color-main;
      color: $color-white;
    }

    &:active {
      background-color: $color-main-dark;
      border-color: $color-main-dark;
      color: $color-white;
    }

    &:disabled,
    &.disabled {
      color: #BBBBBB;
      border-color: #A1DEE6;
      background: transparent;
    }
  }

  &--outline-dark {
    color: $color-white;

    &.focus-visible,
    &:focus-visible {
      border-color: $color-white;
    }

    &:disabled,
    &.disabled {
      color: #5F5F60;
      border-color: #5F5F60;
      background: transparent;
    }
  }

  &--outline-rounded {
    background: transparent;
    color: $color-grey;
    border: 1px solid $color-main;
    border-radius: 20px;
    padding: 6px 20px;
    font-weight: 400;

    &:hover,
    &.focus-visible,
    &:focus-visible {
      color: $color-white;
      background: $color-main;
      outline: none;
    }
  }

  &--big {
    padding: 23px 212px;

    @include mobile {
      padding: 23px 40px;
    }
  }
}

.vacancy {
  padding-right: 20%;
  position: relative;
  display: block;
  color: $color-grey;

  @include mobile {
    padding-right: 0;

    &::after {
      position: static;
      display: block;
      transform: none;
      margin-top: 20px;
      opacity: 1;
    }
  }
}

.vacancy__title {
  font-size: 36px;
  line-height: 1.2;
  font-weight: 600;
  margin: 0 0 15px;


  @include tablet {
    font-size: 30px;
  }

  @include mobile {
    font-size: 20px;
  }
}

.vacancy__link {
  display: block;
  transition: color $timing;
  color: $color-grey;



  &:hover {
    color: $color-main;
  }
}

.vacancy__arrow {
  position: absolute;
  z-index: 1;
  width: 68px;
  height: 68px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 35px;
    background: url("../img/arrow-right-big.svg") no-repeat;
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
    opacity: 0;
    transition: opacity $timing, transform $timing;
    pointer-events: none;
  }

  &:hover {
    &::before {
      opacity: 1;
      transform: translate(0, -50%);
    }
  }
}

.vacancy__info {
  display: flex;
  align-items: baseline;
  color: rgba($color-grey, 0.4);
  margin-bottom: 20px;

  @include mobile {
    display: block;
  }
}

.vacancy__text {
  max-width: 980px;

  p {
    margin: 0;
  }
}

.contacts {
  font-size: 20px;
  line-height: 24px;

  a {
    color: inherit;
    transition: color $timing;

    &:hover,
    &:focus-visible,
    &.focus-visible {
      color: $color-main;
      outline: none;
    }
  }

  @include mobile {
    font-size: 16px;
    line-height: 20px;
  }
}

.contacts__inner {
  padding-top: 70px;
  padding-bottom: 140px;

  display: flex;
  align-items: flex-start;

  @include laptop {
    display: block;
  }

  @include mobile {
    padding-top: 30px;
    padding-bottom: 60px;
  }
}

.contacts__title {
  width: 42%;

  @include laptop {
    margin-bottom: 30px;
    width: auto;
  }
}

.contacts__content {
  display: flex;
  width: 58%;

  @include laptop {
    width: auto;
  }

  @include mobile {
    display: block;
  }
}

.contacts__section-title {
  margin: 0 0 25px;
  font-size: 26px;
  line-height: 31px;
  font-weight: 600;
  color: $color-main;

  padding-bottom: 25px;
  border-bottom: 2px solid $color-main;


  @include mobile {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}

.contacts__list {
  @include list-reset;
}

.contacts__section {
  max-width: 310px;
  margin-right: 80px;

  &:nth-child(2n) {
    margin-right: 0;
    max-width: 415px;
  }

  @include laptop {
    margin-right: 30px;
  }

  @include mobile {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.contacts__list-item {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mobile {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.contacts__map {
  height: 900px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 1440px) {
    height: 500px;
  }
}

.contacts__label {
  max-width: 230px;
  white-space: normal;
}

.history-timeline {
  background: $color-gray-light;

  & + .page-footer {
    background: $color-gray-light;
  }
}

.history-timeline__inner {
  padding: 60px 170px;

  @media (max-width: 1600px) {
    padding-left: 30px;
  }

  @include laptop {
    padding-right: 110px;
  }

  @include tablet {
    padding: 0 30px 60px;
  }
}

.history-timeline__items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  @include tablet {
    display: block;
  }
}

.history-timeline__card {
  width: 50%;
  margin-bottom: 80px;
  padding-right: 125px;
  position: relative;
  z-index: 1;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $color-gray-dark;
    right: -25px;
    top: 0;
  }

  &::after {
    right: 25px;
    width: 100px;
    top: 25px;
    height: 1px;
    border-radius: 0;
  }

  .history-card {
    &::before {
      content: "";
      position: absolute;
      top: 25px;
      bottom: -80px;
      right: -125px;
      width: 1px;
      background: $color-gray-dark;
    }
  }

  &.sal-animate {
    &::before,
    &::after,
    .history-card::before {
      background: $color-main;
    }
  }

  &:nth-child(2n) {
    align-self: flex-end;
    padding-right: 0;
    padding-left: 125px;

    .history-card {
      &::before {
        right: auto;
        left: -125px;
      }
    }

    .history-card__period {
      align-self: flex-start;
      left: 0;
      right: auto;
    }

    &::before {
      right: auto;
      left: -25px;
    }

    &::after {
      right: auto;
      left: 25px;
    }
  }

  &:last-child {
    margin-bottom: 0;

    .history-card {
      &::before {
        display: none;
      }
    }
  }

  @include laptop {
    padding-right: 80px;

    &::after {
      width: 55px;
    }

    .history-card {
      &::before {
        right: -80px;
      }
    }

    &:nth-child(2n) {
      padding-left: 80px;

      .history-card {
        &::before {
          left: -80px;
        }
      }
    }
  }

  @include tablet {
    padding-right: 0;
    width: auto;
    margin-bottom: 60px;

    &::before,
    &::after,
    .history-card::before,
    &:nth-child(2n)::before,
    &:nth-child(2n)::after,
    &:nth-child(2n) .history-card::before {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }

    &::before {
      top: calc(100% + 20px);
      width: 22px;
      height: 22px;
    }

    &::after {
      top: 100%;
      width: 1px;
      height: 20px;
    }

    .history-card {
      &::before {
        top: calc(100% + 42px);
        bottom: -60px;
      }
    }

    &:nth-child(2n) {
      padding-left: 0;
    }

    &:last-child {
      &::before,
      &::after {
        display: none;
      }
    }

    .history-card__period {
      align-self: flex-start;
      left: 0;
      right: auto;
    }
  }
}

.history-timeline__informer {
  position: fixed;
  z-index: 2;
  top: 100px;
  right: 30px;

  font-size: 16px;
  line-height: 1;
  font-weight: 600;

  text-align: right;
  opacity: 0;

  &--show {
    opacity: 1;
  }

  @include laptop {
    font-size: 12px;
    right: 20px;
  }

  @include tablet {
    display: none;
  }
}

.history-timeline__informer-start {
  margin-bottom: 15px;
}

.history-timeline__informer-years {
  font-size: 20px;
  line-height: 30px;
  font-weight: 900;
  margin-bottom: 15px;

  @include laptop {
    font-size: 16px;
  }
}

.history-timeline__informer-years-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: rgba($color-main, 0);
  transition: color $timing;
  padding-right: 50px;
  position: relative;
  z-index: 1;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 1px;
    background: $color-gray;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &::after {
    width: 40px;
    height: 2px;
    background: $color-main;
    transition: opacity $timing;
    opacity: 0;
  }

  &.is-active {
    color: $color-main;

    &::after {
      opacity: 1;
    }
  }

  @include laptop {
    padding-right: 30px;

    &::before {
      width: 10px;
    }

    &::after {
      width: 20px;
    }
  }
}



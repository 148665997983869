@mixin desktop-only {
  @media (min-width: $width-desktop) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: $width-desktop - 1px) {
    @content;
  }
}

@mixin laptop-only {
  @media (max-width: $width-desktop - 1px) and (min-width: $width-laptop) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $width-laptop - 1px) {
    @content;
  }
}

@mixin tablet-only {
  @media (max-width: $width-laptop - 1px) and (min-width: $width-tablet) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $width-tablet - 1px) {
    @content;
  }
}

@mixin min-width {
  @media (max-width: $width-min) {
    @content;
  }
}

@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin font-face($name, $filename, $weight: null, $style: null, $exts: woff2 woff) {
  $src: null;

  @each $ext in $exts {
    $src: append($src, url(quote("../fonts/" + $filename + "." + $ext)) format(quote($ext)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-display: swap;
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin list-reset {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin btn-reset {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
}

@mixin underline($height: 1px) {
  background: linear-gradient(currentColor, currentColor) no-repeat;
  background-position: 0% 100%;
  background-size: 100% $height;
  transition: background-size 0.3s;
}

@mixin col {
	padding-left: 15px;
	padding-right: 15px;
	flex-shrink: 0;
	flex-grow: 1;
}

@mixin get-col($count: 12, $part: 1) {
	flex-basis: percentage($part / $count);
	max-width: percentage($part / $count);
}

@mixin grid-row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
}

@mixin get-ml($count: 12, $part: 1) {
	margin-left: percentage($part / $count);
}

.download-link {
  display: flex;
  align-items: center;

  color: $color-main;
  text-decoration: underline;
  font-weight: 600;

  &:active {
    color: $color-main-dark;
  }

  &.focus-visible,
  &:focus-visible {
    outline: none;
    color: $color-grey;

    .document-link__icon {
      color: $color-main;
    }
  }

  &:disabled,
  &.disabled {
    color: #D1D1D2;
    pointer-events: none;
  }
}

.document-link__icon {
  margin-right: 15px;

  svg {
    display: block;
    fill: none;
  }
}

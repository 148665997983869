.popup {
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;

  padding: 30px 15px;


  align-items: center;
  justify-content: center;

  &--show {
    display: flex;
  }
}

.popup__overlay {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: rgba(27, 27, 28, 0.5);
  cursor: pointer;
}

.popup__content {
  padding: 70px 45px;
  width: 100%;
  max-width: 590px;
  background: $color-white;
  position: relative;


  .popup--small & {
    max-width: 530px;
  }

  @include mobile {
    padding: 60px 24px;
  }
}

.popup__close {
  @include btn-reset;

  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 26px;
  right: 26px;

  svg {
    display: block;
  }
}

.popup__title {
  margin: 0;

  font-size: 32px;
  line-height: 1;
  font-weight: 900;
  text-align: center;

  @include mobile {
    font-size: 24px;
  }
}

.popup__form {
  margin-top: 60px;

  @include mobile {
    margin-top: 30px;
  }
}

.popup__fields {
  margin-bottom: 50px;

  .field {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mobile {
    .field {
      margin-bottom: 15px;
    }
  }
}

.popup__submit {
  margin-bottom: 15px;

  .btn {
    display: block;
    width: 100%;
  }
}

.popup__policy {
  color: #999999;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  max-width: 420px;
}

.popup__desc {
  margin-top: 15px;
  text-align: center;
}

.popup__ok {
  text-align: center;
  margin-top: 60px;

  .btn {
    font-size: 18px;
    line-height: 1.4;
    padding: 19px 100px;
  }
}

.our-client {
  padding-top: 120px;
  padding-bottom: 250px;


  @include tablet {
    padding-top: 50px;
    padding-bottom: 100px;
  }

  @include mobile {
    padding-top: 30px;
    padding-bottom: 60px;
  }
}

.our-client__inner {
  @include mobile {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.our-client__title {
  text-align: center;
  margin-bottom: 70px;

  @include tablet {
    margin-bottom: 50px;
  }
}

.our-client__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

$clip-path: polygon(50% 0, 100% 24%, 100% 76%, 50% 100%, 0 76%, 0 24%);

.our-client__item {
  width: calc((100% - 45px) / 7);
  position: relative;
  z-index: 1;
  margin-bottom: -4.25%;
  transition: transform $timing;


  &:hover {
    transform: scale(1.1);
    z-index: 2;
  }

  &::after {
    content: "";
    display: block;
    padding-bottom: 116.6%;
  }

  .our-client__item__bg {
    position: absolute;
    z-index: -1;
    background: $color-gray-medium;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    clip-path: $clip-path;

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      background: $color-white;
      top: 1px;
      bottom: 1px;
      left: 1px;
      right: 1px;
      clip-path: $clip-path;
    }
  }

  .our-client__image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1201px) {
    &:nth-child(22n + 1) {
      margin-left: 22px;
    }

    &:nth-child(22n + 8),
    &:nth-child(22n + 15),
    &:nth-child(22n + 16),
    &:nth-child(22n + 0) {
      width: calc((100% - 50px) / 12.5);
      margin-right: 0.55%;
    }

    &:nth-child(22n + 15),
    &:nth-child(22n + 0) {
      margin-right: 0;
      margin-left: 0.55%;
    }

    &:nth-child(22n + 16) {
      margin-left: calc(((100% - 50px) / 12.5) - 14px);
    }

    &--medium {
      .our-client__item__bg {
        top: 10%;
        bottom: 11%;
        left: 10%;
        right: 10%;
      }
    }
  }

  @media (max-width: 1500px) {
    width: calc((100% - 90px) / 7);
  }

  @media (max-width: 1440px) {
    img {
      width: auto;
      max-width: 120px;
      max-height: 100px;
    }

    &--small {
      img {
        width: auto;
        max-width: 90px;
        max-height: 80px;
      }
    }
  }

  @media (max-width: 1200px) and (min-width: 1025px) {
    width: calc(100% / 6);
    margin-bottom: -5.1%;

    &:nth-child(11n + 1) {
      margin-left: calc((100% / 6) / 2);
    }
  }

  @media (max-width: 1024px) and (min-width: 641px) {
    width: calc(100% / 4);
    margin-bottom: -7.7%;

    &:nth-child(7n + 1) {
      margin-left: calc((100% / 4) / 2);
    }
  }

  @media (max-width: 640px) and (min-width: 451px) {
    width: calc(100% / 3);
    margin-bottom: -11%;

    &:nth-child(5n + 1) {
      margin-left: calc((100% / 3) / 2);
    }
  }

  @media (max-width: 450px) {
    width: calc(100% / 3);
    margin-bottom: -10%;

    img {
      max-width: 70%;
      max-height: 50%;
      height: auto;
    }

    &:nth-child(7n + 1),
    &:nth-child(7n + 4) {
      width: calc((100% / 3) / 2);
    }

    &:nth-child(7n + 2),
    &:nth-child(7n + 3) {
      .our-client__item__bg {
        top: 7%;
        bottom: 7%;
        left: 7%;
        right: 7%;
      }
    }

    &:last-child {
      display: none;
    }
  }
}

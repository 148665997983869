.slider-with-head {

}

.slider-with-head__header {
  display: flex;
  align-items: flex-start;

  margin-bottom: 90px;

  @include mobile {
    margin-bottom: 40px;
  }
}

.slider-with-head__inner {
  padding-top: 180px;

  @include tablet {
    padding-top: 100px;
  }

  @include mobile {
    padding-top: 60px;
  }
}

.slider-with-head__header {
  display: flex;
  align-items: flex-start;

  @include tablet {
    display: block;
  }
}

.slider-with-head__title,
.slider-with-head__desc {
  width: 50%;

  @include tablet {
    width: auto;
  }
}

.slider-with-head__desc {
  font-size: 24px;
  line-height: 1.5;
  padding-left: 6.67%;
  max-width: 690px;

  p {
    margin: 0;
  }

  @media (max-width: 1600px) {
    font-size: 20px;
  }

  @include tablet {
    padding-left: 0;
  }
}

.slider-with-head__title {
  @media (max-width: 1600px) {
    font-size: 56px;
  }

  @include tablet {
    font-size: 72px;
    margin-bottom: 20px;
  }

  @include mobile {
    font-size: 34px;
  }

  @include tablet {
    margin-bottom: 20px;
  }
}


html {
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  min-width: $width-min;

  margin: 0;
  padding: 0;

  font-family: $font-text;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  color: $color-grey;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  flex-direction: column;
  min-height: 100%;

  padding-top: 170px;

  @include mobile {
    padding-top: 90px;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.noscroll {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.flex {
  display: flex;

  &--center {
    align-items: center;
  }
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mt-50 {
  margin-top: 50px;
}

.white-bg,
.dark-bg {
  padding: 50px;
  background: $color-grey;
}

.white-bg {
  background: $color-white;
}

.intro-gradient-wrap {
  background: linear-gradient(180deg, $color-white 39.4%, $color-gray-light 100%);
}

$cols-count: 12;
$gutter: 30;

.container {
	padding-left: #{$gutter}px;
  padding-right: #{$gutter}px;
	max-width: 1710px;
	margin: 0 auto;
	width: 100%;

	@include mobile {
		padding: 0 15px;
	}
}

.row {
	@include grid-row;

	&--ver-c {
		align-items: center;
	}
}

.col {
	@include col;
	width: 100%;
}

@for $i from 1 through $cols-count {
	.col--#{$i} {
		@include get-col($part: $i);
	}
}

// offsets
@for $i from 1 through $cols-count {
	.col--ml-#{$i} {
		@include get-ml($part: $i);
	}
}

@include tablet {
	.col {
		flex: 0 0 100%;
		max-width: 100%;
		margin: 0;
	}

	@for $i from 1 through $cols-count {
		.col--tb-#{$i} {
			@include get-col($part: $i);
		}
	}

	// offsets
	@for $i from 1 through $cols-count {
		.col--tb-ml-#{$i} {
			@include get-ml($part: $i);
		}
	}
}

@include mobile {
	.col {
		flex: 0 0 100%;
		max-width: 100%;
		margin: 0;
	}

	@for $i from 1 through $cols-count {
		.col--m-#{$i} {
			@include get-col($part: $i);
		}
	}

	// offsets
	@for $i from 1 through $cols-count {
		.col--m-ml-#{$i} {
			@include get-ml($part: $i);
		}
	}
}

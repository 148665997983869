.user-text {
  font-size: 18px;
  line-height: 1.4;

  h2 {
    font-size: 52px;
    line-height: 1.2;
    font-weight: 700;
    margin: 0;

    margin-bottom: 30px;
    margin-top: 80px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    margin: 15px 0;
    max-width: 900px;
  }

  ul {
    margin: 30px 0;
    list-style: none;
    padding: 0;

    font-size: 24px;

    li {
      padding-left: 30px;
      position: relative;
      z-index: 1;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 16px;
        height: 16px;
        background: $color-main;
        border-radius: 50%;
      }
    }
  }

  @include tablet {
    font-size: 16px;

    h2 {
      font-size: 48px;
    }

    ul {
      font-size: 20px;
    }
  }

  @include mobile {
    h2 {
      font-size: 28px;
      margin-top: 50px;
      margin-bottom: 20px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      font-size: 18px;
    }
  }
}

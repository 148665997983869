.breadcrumbs {

}

.breadcrumbs__list {
  @include list-reset;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: -15px;
}

.breadcrumbs__item {
  display: flex;
  align-items: center;

  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }

  &:last-child {
    .breadcrumbs__link {
      color: $color-grey;
    }
  }
}

.breadcrumbs__link {
  color: rgba($color-grey, 0.4);
  transition: color $timing;


  &[href] {
    &:hover,
    &:focus-visible,
    &.focus-visible {
      color: $color-main;
    }
  }
}

.breadcrumbs__item-icon {
  margin-left: 15px;

  svg {
    display: block;
  }
}

.about-history {
  background: $color-gray-light;

  padding: 120px 0;

  @include tablet {
    padding: 80px 0;
  }

  @include mobile {
    padding: 60px 0;
  }
}

.about-history__inner {
  display: flex;
  align-items: center;

  @include tablet {
    display: block;
  }
}

.about-history__content {
  width: percentage(7 / 12);
  padding-right: 15px;

  @include tablet {
    padding-right: 0;
    width: auto;
    margin-bottom: 60px;
  }
}

.about-history__image {
  padding-left: 15px;
  width: percentage(5 / 12);
  text-align: right;

  @include tablet {
    padding-left: 0;
    width: auto;
    text-align: center;
  }
}

.about-history__title {
  margin-bottom: 40px;

  @media (max-width: 1600px) {
    font-size: 56px;
  }

  @include tablet {
    font-size: 34px;
    margin-bottom: 20px;
  }
}

.about-history__text {
  max-width: 700px;
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 60px;

  p {
    margin: 0 0 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1600px) {
    font-size: 20px;
  }

  @include tablet {
    font-size: 16px;
    margin-bottom: 30px;
  }
}
